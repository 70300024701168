import styled from "styled-components"

export const MobileMainLinkStyle = styled.div`
  .menu-container {
    ul {
      list-style-type: none;
      color: #5595f7;
      width: 100%;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 0 10px 30px;
        font-size: large;
        display: grid;
        grid-template-columns: 10% 80% 10%;
        align-items: center;
        border-bottom: 1px solid rgba(85, 149, 247, 0.1);

        &:hover {
          cursor: pointer;
          background-color: #5595f7;
          color: white;
        }
      }
    }
  }
`
