import styled from "styled-components"

export const MainNavDropDownStyles = styled.div`
  #dropdown-container {
    position: fixed;
    background-color: grey;
    display: grid;
    grid-template-columns: 20% 80%;
    height: 573px;
    width: 100%;
    padding-top: 70px;
    z-index: 9;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
      height: 560px;
    }

    @media (max-width: 1360px) {
      height: 800px;
    }
    @media (max-width: 1140px) {
    }
    #left-container {
      background-color: whitesmoke;
      z-index: 2;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 20%;
        height: 573px;
      }

      @media (max-width: 1360px) {
        height: 800px;
      }

      @media (max-width: 1140px) {
      }

      #main-nav {
        padding: 1rem 0 1rem 1rem;
        z-index: 2;

        @media (max-width: 1360px) {
          height: 800px;
        }

        @media (max-width: 1140px) {
        }
        ul {
          list-style-type: none;
          padding: 0;

          li {
            padding: 1rem 0 1rem 0.5rem;
            font-size: 1.3em;

            &:hover {
              cursor: pointer;
              background-color: #5595f7;
              color: white;
            }

            &:active {
              background-color: #5595f7;
            }
          }

          li:active {
            background-color: #5595f7;
          }
          li:target {
            background-color: #5595f7;
          }
        }
      }
    }

    #right-container {
      position: relative;
      height: 573px;

      #s-series-images {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-left: 350px;
          display: -ms-flexbox;
        }
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 80%;
      }

      .title-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* width: 500px; */
      }

      @media (max-width: 1360px) {
        height: 800px;
      }

      @media (max-width: 1140px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .sub-nav {
        position: absolute;
        top: 0;
        background: rgba(85, 149, 247, 0.8);
        margin-left: 0px;
        height: 100%;
        width: 28%;
        color: white;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 23%;
        }

        @media (max-width: 1140px) {
          display: none;
        }

        ul {
          list-style-type: none;
          li {
            padding: 1rem;
            &:hover {
              background-color: #5595f7;
              cursor: pointer;
            }

            p:active {
              background-color: #5595f7;
            }
          }
        }

        .sub-nav-title {
          padding: 1rem;
          h1 {
            font-size: 1.6em;
          }
          h3 {
            font-size: 1.2em;
            font-style: italic;
          }
        }
      }
      #s-series-container {
        display: grid;
        grid-template-columns: 30% 70%;
        background-color: lightgray;
        height: 100%;

        #s-series-images {
          padding-top: 35px;
          display: flex;
          justify-content: space-evenly;
          overflow: hidden;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;

          img {
            width: 200px;
            height: 200px;
          }
        }
      }
    }
  }
`
