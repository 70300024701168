import React, { Component } from "react"
import { MainNavDropDownStyles } from "./styles/MainNavDropDownStyles"
import '../navigationV2/mainnav.scss'
import { Link } from "gatsby"
import { If } from "rc-if-else"
import "core-js"

import Image1 from "../../images/timberline-machine-lineup-mall.png"
import SImage1 from "../../images/TIMBERLINE-S-SERIES-Nav-Disk.png"
import SImage2 from "../../images/TIMBERLINE-S-SERIES-24-C-Nav-WEB.png"
import SImage3 from "../../images/TIMBERLINE-S-SERIES-20-O-Nav-WEB.png"
import MImage1 from "../../images/TIMBERLINE-M-SERIES-26-D-Nav-WEB.png"
import MImage2 from "../../images/TIMBERLINE-M-SERIES-26-C-Nav-WEB.png"
import MImage3 from "../../images/TIMBERLINE-M-SERIES-28-O-nav-WEB.png"
import LImage1 from "../../images/TIMBERLINE-L-SERIES-28-D-Nav-WEB.png"
import LImage2 from "../../images/TIMBERLINE-L-SERIES-30-C-Nav-WEB.png"
import LImage3 from "../../images/TIMBERLINE-L-SERIES-28-O-Nav-WEB.png"
import DiskImage from "../../images/20-inch-disk-view-insidex150.png"
import CylImage from "../../images/s-series-grout-scrub-deck-illustration.png"
// import OrbitalImage from "../../images/orbital-scrubberx150.png"
import OrbitalImage from "../../images/orbital-scrubberx150.png"
import DualDiskImage from "../../images/disk-deck-150.png"

class MainNavDropDown extends Component {
  state = {
    fmSubNavPosition: "-500px",
    fmSubNavIndex: "-1",
    sxSubNavPosition: "-500px",
    sxSubNavIndex: "-1",
    s1SubNavPosition: "-500px",
    s1SubNavIndex: "-1",
    sSubNavPosition: "-500px",
    sSubNavIndex: "-1",
    mSubNavPosition: "-500px",
    mSubNavIndex: "-1",
    lSubNavPosition: "-500px",
    lSubNavIndex: "-1",
    // xlSubNavPosition: "-500px",
    subNavImages: false,
    mainImage: true,
    fmActiveSize: "",
    sxActiveSize: "",
    s1ActiveSize: "",
    sActiveSize: "",
    mActiveSize: "",
    lActiveSize: "",
    xlActiveSize: "",
    fmColor: "",
    sxColor: "",
    s1Color: "",
    sColor: "",
    mColor: "",
    lColor: "",
    xlColor: "",
    fmSubNavImages: false,
    sxSubNavImages: false,
    s1SubNavImages: false,
    sSubNavImages: false,
    mSubNavImages: false,
    lSubNavImages: false,
    xlSubNavImages: false,

    diskHeight: "200px",
    diskWidth: "200px",
    diskGray: "grayscale(100%)",

    cylHeight: "200px",
    cylWidth: "200px",
    cylGray: "grayscale(100%)",

    orbHeight: "200px",
    orbWidth: "200px",
    orbGray: "grayscale(100%)",

    diskIconWidth: "75px",
    diskIconHeight: "70px",
    cylIconWidth: "100px",
    cylIconHeight: "70px",
    orbitalIconWidth: "100px",
    orbitalIconHeight: "70px",
  }

  fmHandleClick = () => {
    this.setState({
      fmActiveSize: "#5595f7",
      sxActiveSize: "",
      s1ActiveSize: "",
      sActiveSize: "",
      mActiveSize: "",
      lActiveSize: "",
      xlActiveSize: "",
      fmColor: "white",
      sxColor: "",
      s1Color: "",
      sColor: "",
      mColor: "",
      lColor: "",
      xlColor: "",
      fmSubNavPosition: "0",
      fmSubNavIndex: "1",
      sxSubNavPosition: "-500",
      sxSubNavIndex: "-1",
      s1SubNavPosition: "-500",
      s1SubNavIndex: "-1",
      sSubNavPosition: "-500px",
      sSubNavIndex: "-1",
      mSubNavPosition: "-500px",
      mSubNavIndex: "-1",
      lSubNavPosition: "-500px",
      lSubNavIndex: "-1",
      xlSubNavPosition: "-500px",

      fmSubNavImages: true,
      sxSubNavImages: false,
      s1SubNavImages: false,
      sSubNavImages: false,
      mSubNavImages: false,
      lSubNavImages: false,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.sSubNavPosition === "0") {
      this.setState({
        fmSubNavPosition: "-500px",
        fmSubNavIndex: "-1",
        mainImage: true,
        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  sxHandleClick = () => {
    this.setState({
      fmActiveSize: "",
      sxActiveSize: "#5595f7",
      s1ActiveSize: "",
      sActiveSize: "",
      mActiveSize: "",
      lActiveSize: "",
      xlActiveSize: "",
      fmColor: "",
      sxColor: "white",
      s1Color: "",
      sColor: "",
      mColor: "",
      lColor: "",
      xlColor: "",
      fmSubNavPosition: "-500",
      fmSubNavIndex: "-1",
      sxSubNavPosition: "0",
      sxSubNavIndex: "1",
      s1SubNavPosition: "-500",
      s1SubNavIndex: "-1",
      sSubNavPosition: "-500px",
      sSubNavIndex: "-1",
      mSubNavPosition: "-500px",
      mSubNavIndex: "-1",
      lSubNavPosition: "-500px",
      lSubNavIndex: "-1",
      xlSubNavPosition: "-500px",

      fmSubNavImages: false,
      sxSubNavImages: true,
      s1SubNavImages: false,
      sSubNavImages: false,
      mSubNavImages: false,
      lSubNavImages: false,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.sSubNavPosition === "0") {
      this.setState({
        sxSubNavPosition: "-500px",
        sxSubNavIndex: "-1",
        mainImage: true,
        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  s1HandleClick = () => {
    this.setState({
      fmActiveSize: "",
      sxActiveSize: "",
      s1ActiveSize: "#5595f7",
      sActiveSize: "",
      mActiveSize: "",
      lActiveSize: "",
      xlActiveSize: "",
      fmColor: "",
      sxColor: "",
      s1Color: "white",
      sColor: "",
      mColor: "",
      lColor: "",
      xlColor: "",
      fmSubNavPosition: "-500",
      fmSubNavIndex: "-1",
      sxSubNavPosition: "-500",
      sxSubNavIndex: "-1",
      s1SubNavPosition: "0",
      s1SubNavIndex: "1",
      sSubNavPosition: "-500px",
      sSubNavIndex: "-1",
      mSubNavPosition: "-500px",
      mSubNavIndex: "-1",
      lSubNavPosition: "-500px",
      lSubNavIndex: "-1",
      xlSubNavPosition: "-500px",

      fmSubNavImages: false,
      sxSubNavImages: false,
      s1SubNavImages: true,
      sSubNavImages: false,
      mSubNavImages: false,
      lSubNavImages: false,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.sSubNavPosition === "0") {
      this.setState({
        s1SubNavPosition: "-500px",
        s1SubNavIndex: "-1",
        mainImage: true,
        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  sHandleClick = () => {
    this.setState({
      fmActiveSize: "",
      sxActiveSize: "",
      s1ActiveSize: "",
      sActiveSize: "#5595f7",
      mActiveSize: "",
      lActiveSize: "",
      xlActiveSize: "",

      fmColor: "",
      sxColor: "",
      s1Color: "",
      sColor: "white",
      mColor: "",
      lColor: "",
      xlColor: "",

      fmSubNavPosition: "-500px",
      fmSubNavIndex: "-1",
      sxSubNavPosition: "-500px",
      sxSubNavIndex: "-1",
      s1SubNavPosition: "-500px",
      s1SubNavIndex: "-1",
      sSubNavPosition: "0",
      sSubNavIndex: "1",
      mSubNavPosition: "-500px",
      mSubNavIndex: "-1",
      lSubNavPosition: "-500px",
      lSubNavIndex: "-1",
      xlSubNavPosition: "-500px",

      fmSubNavImages: false,
      sxSubNavImages: false,
      s1SubNavImages: false,
      sSubNavImages: true,
      mSubNavImages: false,
      lSubNavImages: false,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.sSubNavPosition === "0") {
      this.setState({
        sSubNavPosition: "-500px",
        sSubNavIndex: "-1",
        mainImage: true,
        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  mHandleClick = () => {
    this.setState({
      fmSubNavPosition: "-500px",
      fmSubNavIndex: "-1",
      sxSubNavPosition: "-500px",
      sxSubNavIndex: "-1",
      s1SubNavPosition: "-500px",
      s1SubNavIndex: "-1",
      sSubNavPosition: "-500px",
      sSubNavIndex: "-1",
      mSubNavPosition: "0",
      mSubNavIndex: "1",
      lSubNavPosition: "-500px",
      lSubNavIndex: "-1",
      xlSubNavPosition: "-500px",

      fmActiveSize: "",
      sxActiveSize: "",
      s1ActiveSize: "",
      sActiveSize: "",
      mActiveSize: "#5595f7",
      lActiveSize: "",
      xlActiveSize: "",

      fmColor: "",
      sxColor: "",
      s1Color: "",
      sColor: "",
      mColor: "white",
      lColor: "",
      xlColor: "",

      fmSubNavImages: false,
      sxSubNavImages: false,
      s1SubNavImages: false,
      sSubNavImages: false,
      mSubNavImages: true,
      lSubNavImages: false,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.mSubNavPosition === "0") {
      this.setState({
        mSubNavPosition: "-500px",
        mSubNavIndex: "-1",
        mainImage: true,

        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  lHandleClick = () => {
    this.setState({
      fmSubNavPosition: "-500px",
      fmSubNavIndex: "-1",
      sxSubNavPosition: "-500px",
      sxSubNavIndex: "-1",
      s1SubNavPosition: "-500px",
      s1SubNavIndex: "-1",
      sSubNavPosition: "-500px",
      sSubNavIndex: "-1",
      mSubNavPosition: "-500px",
      mSubNavIndex: "-1",
      lSubNavPosition: "0",
      lSubNavIndex: "1",
      xlSubNavPosition: "-500px",

      fmActiveSize: "",
      sxActiveSize: "",
      s1ActiveSize: "",
      sActiveSize: "",
      mActiveSize: "",
      lActiveSize: "#5595f7",
      xlActiveSize: "",

      fmColor: "",
      sxColor: "",
      s1Color: "",
      sColor: "",
      mColor: "",
      lColor: "white",
      xlColor: "",
      sColorText: "gray",
      mColorText: "gray",
      lColorText: "gray",

      fmSubNavImages: false,
      sxSubNavImages: false,
      s1SubNavImages: false,
      sSubNavImages: false,
      mSubNavImages: false,
      lSubNavImages: true,
      xlSubNavImages: false,
      mainImage: false,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.lSubNavPosition === "0") {
      this.setState({
        lSubNavPosition: "-500px",
        lSubNavIndex: "-1",
        mainImage: true,

        fmSubNavImages: false,
        sxSubNavImages: false,
        s1SubNavImages: false,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  xlHandleClick = () => {
    this.setState({
      sSubNavPosition: "-500px",
      mSubNavPosition: "-500px",
      lSubNavPosition: "-500px",
      xlSubNavPosition: "0",
      sActiveSize: "",
      mActiveSize: "",
      lActiveSize: "",
      xlActiveSize: "#5595f7",
      sColor: "",
      mColor: "",
      lColor: "",
      xlColor: "white",

      sSubNavImages: false,
      mSubNavImages: false,
      lSubNavImages: false,
      xlSubNavImages: true,
      mainImage: true,

      diskGray: "grayscale(100%)",
      diskHeight: "200px",
      diskWidth: "200px",

      cylHeight: "200px",
      cylWidth: "200px",
      cylGray: "grayscale(100%)",

      orbHeight: "200px",
      orbWidth: "200px",
      orbGray: "grayscale(100%)",
    })

    if (this.state.xlSubNavPosition === "0") {
      this.setState({
        xlSubNavPosition: "-500px",
        mainImage: true,
        sSubNavImages: false,
        mSubNavImages: false,
        lSubNavImages: false,
        xlSubNavImages: false,
      })
    }
  }

  selectDiskScrubber = () => {
    if (this.state.diskWidth === "200px") {
      this.setState({
        diskGray: "grayscale(0%)",
        diskHeight: "300px",
        diskWidth: "300px",

        cylHeight: "200px",
        cylWidth: "200px",
        cylGray: "grayscale(100%)",

        orbHeight: "200px",
        orbWidth: "200px",
        orbGray: "grayscale(100%)",

        diskIconWidth: "150px",
        diskIconHeight: "140px",
        cylIconWidth: "100px",
        cylIconHeight: "70px",
        orbitalIconWidth: "100px",
        orbitalIconHeight: "70px",
      })
    }
  }

  selectCylScrubber = () => {
    if (this.state.cylWidth === "200px") {
      this.setState({
        diskGray: "grayscale(100%)",
        diskHeight: "200px",
        diskWidth: "200px",

        cylHeight: "300px",
        cylWidth: "300px",
        cylGray: "grayscale(0%)",

        orbHeight: "200px",
        orbWidth: "200px",
        orbGray: "grayscale(100%)",

        diskIconWidth: "75px",
        diskIconHeight: "70px",
        cylIconWidth: "150px",
        cylIconHeight: "100px",
        orbitalIconWidth: "100px",
        orbitalIconHeight: "70px",
      })
    }
  }

  selectOrbScrubber = () => {
    if (this.state.orbWidth === "200px") {
      this.setState({
        diskGray: "grayscale(100%)",
        diskHeight: "200px",
        diskWidth: "200px",
        cylHeight: "200px",
        cylWidth: "200px",
        cylGray: "grayscale(100%)",
        orbHeight: "300px",
        orbWidth: "300px",
        orbGray: "grayscale(0%)",
        diskIconWidth: "75px",
        diskIconHeight: "70px",
        cylIconWidth: "100px",
        cylIconHeight: "70px",
        orbitalIconWidth: "150px",
        orbitalIconHeight: "100px",
      })
    }
  }

  render() {
    return (
      <div >
        <div>
          <div
            style={{
              marginTop: this.props.dropDownMargin,
              transitionDuration: "0.5s",
            }}
            id="dropdown-container"
          >
            <div id="left-container">
              <div id="main-nav">
                <h1 alt="View our Commercial Floor Scrubbers">
                  Floor Scrubbers
                </h1>
                <p style={{ fontSize: "1.2em", fontStyle: "italic" }}>
                  Choose your size
                </p>
                <ul>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/floor-machine-series"
                  >
                    <li
                      style={{
                        background: this.state.fmActiveSize,
                        color: this.state.fmColor,
                      }}
                      onClick={this.fmHandleClick}
                      title="S – Series Walk Behind Floor Scrubber"
                      alt="S – Series Walk Behind Floor Scrubber"
                    >
                      Floor Machines{" "}
                      <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                        10 - 20in Cleaning Path
                      </span>
                    </li>
                  </Link>
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/xs-standard-series"
                  >
                    <li
                      style={{
                        background: this.state.sxActiveSize,
                        color: this.state.sxColor,
                      }}
                      title="S – Series Walk Behind Floor Scrubber"
                      alt="S – Series Walk Behind Floor Scrubber"
                      onClick={this.sxHandleClick}
                    >
                      XS-Standard{" "}
                      <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                        17 - 20in Cleaning Path
                      </span>
                    </li>
                  </Link> */}
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/xs-compact-series"
                  >
                    <li
                      style={{
                        background: this.state.s1ActiveSize,
                        color: this.state.s1Color,
                      }}
                      title="S – Series Walk Behind Floor Scrubber"
                      alt="S – Series Walk Behind Floor Scrubber"
                      onClick={this.s1HandleClick}
                    >
                      XS-Compact{" "}
                      <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                        17 - 20in Cleaning Path
                      </span>
                    </li>
                  </Link> */}
                  <li
                    style={{
                      background: this.state.sActiveSize,
                      color: this.state.sColor,
                    }}
                    title="S – Series Walk Behind Floor Scrubber"
                    alt="S – Series Walk Behind Floor Scrubber"
                    onClick={this.sHandleClick}
                  >
                    S-Series{" "}
                    <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                      17 - 20in Cleaning Path
                    </span>
                  </li>
                  <li
                    style={{
                      background: this.state.mActiveSize,
                      color: this.state.mColor,
                    }}
                    title="M – Series Walk Behind Floor Scrubber"
                    alt="M – Series Walk Behind Floor Scrubber"
                    onClick={this.mHandleClick}
                  >
                    M-Series{" "}
                    <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                      20 - 28in Cleaning Path
                    </span>
                  </li>
                  <li
                    style={{
                      background: this.state.lActiveSize,
                      color: this.state.lColor,
                    }}
                    title="M – Series Walk Behind Floor Scrubber"
                    alt="M – Series Walk Behind Floor Scrubber"
                    onClick={this.lHandleClick}
                  >
                    L-Series{" "}
                    <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                      24 - 28in Cleaning Path
                    </span>
                  </li>

                  <li
                    style={{
                      background: this.state.xlActiveSize,
                      color: this.state.xlColor,
                    }}
                    title="M – Series Walk Behind Floor Scrubber"
                    alt="M – Series Walk Behind Floor Scrubber"
                    onClick={this.xlHandleClick}
                  >
                    XL-Series{" "}
                    <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                      24 - 32in Cleaning Path
                    </span>
                  </li>
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/products/r-series-scrubber"
                  >
                    <li
                      // style={{
                      //   background: this.state.xlActiveSize,
                      //   color: this.state.xlColor,
                      // }}
                      title="M – Series Walk Behind Floor Scrubber"
                      alt="M – Series Walk Behind Floor Scrubber"
                      // onClick={this.xlHandleClick}
                    >
                      Rider-Series{" "}
                      <span style={{ fontStyle: "italic", fontSize: ".6em" }}>
                        24 - 32in Cleaning Path
                      </span>
                    </li>
                  </Link> */}

                  {/* <li
                    style={{
                      background: this.state.xlActiveSize,
                      color: this.state.xlColor,
                    }}
                    title="L – Series Walk Behind Floor Scrubber"
                    alt="L – Series Walk Behind Floor Scrubber"
                    onClick={this.xlHandleClick}
                  >
                    XL-Series
                  </li> */}
                </ul>
              </div>
            </div>
            <div id="right-container">
              <If condition={this.state.mainImage}>
                <img src={Image1} alt="" />
              </If>

              <If condition={this.state.sSubNavImages}>
                <div id="s-series-container">
                  <div className="empty-div"></div>
                  <div id="s-series-images">
                    <div className="nav-img-container-ie">
                      <img
                        style={{
                          filter: this.state.diskGray,
                          width: this.state.diskWidth,
                          height: this.state.diskHeight,
                          transitionDuration: ".5s",
                        }}
                        src={SImage1}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Disk</h4>
                        <img
                          style={{
                            width: this.state.diskIconWidth,
                            height: this.state.diskIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={DiskImage}
                        />
                      </div>
                      <ul>
                        <li>Wavy / Irregular Floors</li>
                        <li>Lowest Maintenance Cost</li>
                        <li>Allows Pads</li>
                      </ul>
                    </div>
                    <div>
                      <img
                        style={{
                          filter: this.state.cylGray,
                          width: this.state.cylWidth,
                          height: this.state.cylHeight,
                          transitionDuration: ".5s",
                        }}
                        src={SImage2}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Grout</h4>
                        <img
                          style={{
                            width: this.state.cylIconWidth,
                            height: this.state.cylIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={CylImage}
                        />
                      </div>
                      <ul>
                        <li>Scrub & Sweep Simultaneously </li>
                        <li>Tile & Grout Cleaning </li>
                        <li>Rubber Surfaces & Track Fields</li>
                      </ul>
                    </div>
                    <div>
                      <img
                        style={{
                          filter: this.state.orbGray,
                          width: this.state.orbWidth,
                          height: this.state.orbHeight,
                          transitionDuration: ".5s",
                        }}
                        src={SImage3}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Orbital</h4>
                        <img
                          style={{
                            width: this.state.orbitalIconWidth,
                            height: this.state.orbitalIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={OrbitalImage}
                        />
                      </div>
                      <ul>
                        <li>Chemical Free Stripping </li>
                        <li>70% Reduction in Water Usage </li>
                        <li>Floor Finish Prep & Recoat </li>
                      </ul>
                    </div>
                    {/* <img
                        style={{
                          filter: "grayscale(100%)",
                          width: "200px",
                          height: "200px",
                        }}
                        src={Image2}
                        alt=""
                      />
                      <img
                        style={{
                          filter: "grayscale(100%)",
                          width: "200px",
                          height: "200px",
                        }}
                        src={Image2}
                        alt=""
                      /> */}
                  </div>
                </div>
              </If>

              <If condition={this.state.mSubNavImages}>
                <div id="s-series-container">
                  <div className="empty-div"></div>
                  <div id="s-series-images">
                    <div>
                      <img
                        style={{
                          filter: this.state.diskGray,
                          width: this.state.diskWidth,
                          height: this.state.diskHeight,
                          transitionDuration: ".5s",
                        }}
                        src={MImage1}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Disk</h4>
                        <img
                          style={{
                            width: this.state.diskIconWidth,
                            height: this.state.diskIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={DualDiskImage}
                        />
                      </div>
                      <ul>
                        <li>Wavy / Irregular Floors</li>
                        <li>Lowest Maintenance Cost</li>
                        <li>Allows Pads</li>
                      </ul>
                    </div>
                    <div>
                      <img
                        style={{
                          filter: this.state.cylGray,
                          width: this.state.cylWidth,
                          height: this.state.cylHeight,
                          transitionDuration: ".5s",
                        }}
                        src={MImage2}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Cylindrical</h4>
                        <img
                          style={{
                            width: this.state.cylIconWidth,
                            height: this.state.cylIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={CylImage}
                        />
                      </div>
                      <ul>
                        <li>Scrub & Sweep Simultaneously </li>
                        <li>Tile & Grout Cleaning </li>
                        <li>Rubber Surfaces & Track Fields</li>
                      </ul>
                    </div>
                    <div>
                      <img
                        style={{
                          filter: this.state.orbGray,
                          width: this.state.orbWidth,
                          height: this.state.orbHeight,
                          transitionDuration: ".5s",
                        }}
                        src={MImage3}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Orbital</h4>
                        <img
                          style={{
                            width: this.state.orbitalIconWidth,
                            height: this.state.orbitalIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={OrbitalImage}
                        />
                      </div>
                      <ul>
                        <li>Chemical Free Stripping </li>
                        <li>70% Reduction in Water Usage </li>
                        <li>Floor Finish Prep & Recoat </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </If>

              <If condition={this.state.lSubNavImages}>
                <div id="s-series-container">
                  <div className="empty-div"></div>
                  <div id="s-series-images">
                    <div>
                      <img
                        style={{
                          filter: this.state.diskGray,
                          width: this.state.diskWidth,
                          height: this.state.diskHeight,
                          transitionDuration: ".5s",
                        }}
                        src={LImage1}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Disk</h4>
                        <img
                          style={{
                            width: this.state.diskIconWidth,
                            height: this.state.diskIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={DualDiskImage}
                        />
                      </div>
                      <ul>
                        <li>Wavy / Irregular Floors</li>
                        <li>Lowest Maintenance Cost</li>
                        <li>Allows Pads</li>
                      </ul>
                    </div>
                    <div>
                      <img
                        style={{
                          filter: this.state.cylGray,
                          width: this.state.cylWidth,
                          height: this.state.cylHeight,
                          transitionDuration: ".5s",
                        }}
                        src={LImage2}
                        alt=""
                      />
                      <div className="title-container">
                        <h4>Cylindrical</h4>
                        <img
                          style={{
                            width: this.state.cylIconWidth,
                            height: this.state.cylIconHeight,
                            transitionDuration: ".5s",
                          }}
                          src={CylImage}
                        />
                      </div>
                      <ul>
                        <li>Scrub & Sweep Simultaneously </li>
                        <li>Tile & Grout Cleaning </li>
                        <li>Rubber Surfaces & Track Fields</li>
                      </ul>
                    </div>
                    <div>
                      <div>
                        <img
                          style={{
                            filter: this.state.orbGray,
                            width: this.state.orbWidth,
                            height: this.state.orbHeight,
                            transitionDuration: ".5s",
                          }}
                          src={LImage3}
                          alt=""
                        />
                        <div className="title-container">
                          <h4>Orbital</h4>
                          <img
                            style={{
                              width: this.state.orbitalIconWidth,
                              height: this.state.orbitalIconHeight,
                              transitionDuration: ".5s",
                            }}
                            src={OrbitalImage}
                          />
                        </div>
                        <ul>
                          <li>Chemical Free Stripping </li>
                          <li>70% Reduction in Water Usage </li>
                          <li>Floor Finish Prep & Recoat </li>
                        </ul>
                      </div>
                    </div>
                    {/* <img
                        style={{
                          filter: "grayscale(100%)",
                          width: "200px",
                          height: "200px",
                        }}
                        src={Image2}
                        alt=""
                      />
                      <img
                        style={{
                          filter: "grayscale(100%)",
                          width: "200px",
                          height: "200px",
                        }}
                        src={Image2}
                        alt=""
                      /> */}
                  </div>
                </div>
              </If>

              <div
                id="s-series-sub-nav"
                className="sub-nav"
                style={{
                  backgroundColor: "rgba(85, 149, 247)",
                  marginLeft: this.state.sSubNavPosition,
                  transitionDuration: ".5s",
                  zIndex: this.state.sSubNavIndex,
                }}
              >
                <div
                  id="floor-series-sub-nav"
                  className="sub-nav"
                  style={{
                    marginLeft: this.state.fmSubNavPosition,
                    transitionDuration: ".5s",
                    zIndex: this.state.fmSubNavIndex,
                  }}
                >
                  <div className="sub-nav-title" style={{ color: "white" }}>
                    <h1>Walk Behind Floor Scrubber</h1>
                    <h3>Floor Machines</h3>
                  </div>
                  <ul>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/products/m20-disk-scrubber"
                    >
                      <li onMouseOver={this.selectDiskScrubber}>ORBITZ NANO</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/products/m26-disk-scrubber"
                    >
                      <li onMouseOver={this.selectDiskScrubber}>
                        ORBITZ 42-Volt NANO
                      </li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/products/m28-disk-scrubber"
                    >
                      <li onMouseOver={this.selectDiskScrubber}>
                        ORBITZ 20 Floor Machine
                      </li>
                    </Link>
                  </ul>
                </div>

                <div className="sub-nav-title" style={{ color: "white" }}>
                  <h1>Walk Behind Floor Scrubber</h1>
                  <h3>Small - Choose your Deck</h3>
                  {/* <h3>*** COMING SOON ***</h3> */}
                </div>
                <ul>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s17-disk-scrubber-pad-assist"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      S17 Disk Scrubber - Pad Assist
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s20-disk-scrubber-pad-assist"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      S20 Disk Scrubber - Pad Assist
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s17-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      S17 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s20-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      S20 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s24-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      S24 Cylindrical Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s20-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      S20 Orbital Scrubber
                    </li>
                  </Link>
                </ul>
              </div>

              <div
                id="m-series-sub-nav"
                className="sub-nav"
                style={{
                  marginLeft: this.state.mSubNavPosition,
                  transitionDuration: ".5s",
                  zIndex: this.state.mSubNavIndex,
                }}
              >
                <div className="sub-nav-title" style={{ color: "white" }}>
                  <h1>Walk Behind Floor Scrubber</h1>
                  <h3>Medium - Choose your Deck</h3>
                </div>
                <ul>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m20-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      M20 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m26-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      M26 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m28-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      M28 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m26-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      M26 Cylindrical Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m20-orbital-scrubber"
                  >
                    {/* <li onMouseOver={this.selectOrbScrubber}>
                      M20 Orbital Scrubber
                    </li> */}
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m24-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      M24 Orbital Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m28-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      M28 Orbital Scrubber
                    </li>
                  </Link>
                </ul>
              </div>

              <div
                id="l-series-sub-nav"
                className="sub-nav"
                style={{
                  marginLeft: this.state.lSubNavPosition,
                  transitionDuration: ".5s",
                  zIndex: this.state.lSubNavIndex,
                }}
              >
                <div className="sub-nav-title" style={{ color: "white" }}>
                  <h1>Walk Behind Floor Scrubber</h1>
                  <h3>Large - Choose your Deck</h3>
                </div>
                <ul>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l26-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      L26 Disk Scrubber
                    </li>
                  </Link>

                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l28-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      L28 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l26-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      L26 Cylindrical Scrubber
                    </li>
                  </Link>
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l30-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      L30 Cylindrical Scrubber
                    </li>
                  </Link> */}
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l24-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      L24 Orbital Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l28-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      L28 Orbital Scrubber
                    </li>
                  </Link>
                </ul>
              </div>

              <div
                id="xl-series-sub-nav"
                className="sub-nav"
                style={{
                  marginLeft: this.state.xlSubNavPosition,
                  transitionDuration: ".5s",
                }}
              >
                <div className="sub-nav-title" style={{ color: "white" }}>
                  <h1>Walk Behind Floor Scrubber</h1>
                </div>
                <ul>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl28-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      XL28 Disk Scrubber
                    </li>
                  </Link>

                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl32-disk-scrubber"
                  >
                    <li onMouseOver={this.selectDiskScrubber}>
                      XL32 Disk Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl26-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      XL26 Cylindrical Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl30-cylindrical-scrubber"
                  >
                    <li onMouseOver={this.selectCylScrubber}>
                      XL30 Cylindrical Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl24-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      XL24 Orbital Scrubber
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl28-orbital-scrubber"
                  >
                    <li onMouseOver={this.selectOrbScrubber}>
                      XL28 Orbital Scrubber
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainNavDropDown
