import React from "react"
import { MobileMainLinkStyle } from "./styles/MobileMainLinksStyle"
import Icon from "../../images/Rubicon-floor-scrubber-icon-thick.png"
import IconWhite from "../../images/Rubicon-scrubber-machine-icon.png"
import { Link } from "gatsby"

const MobileNavMainLinks = props => {
  return (
    <div>
      <MobileMainLinkStyle>
        <div className="menu-container">
          <ul>
            <li onClick={props.toggleProductMenu}>
              <img
                style={{ width: "25px" }}
                src={Icon}
                alt="floor-scrubber-icon"
              />
              {/* <img
                style={{ width: "25px" }}
                src={IconWhite}
                alt="floor-scrubber-icon"
              /> */}
              {/* <i className="fas fa-subway"></i> */}
              <p>Floor Scrubbers</p>
              <i className="fas fa-chevron-right"></i>
            </li>
            <Link style={{ textDecoration: "none" }} to="/support">
              <li>
                <i className="far fa-question-circle"></i>
                <p>Support</p>
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/about">
              <li>
                <i class="far fa-address-card"></i>
                <p>About</p>
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/">
              <li>
                <i class="fa fa-home"></i>
                <p>Home</p>
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/videos">
              <li>
                <i className="fas fa-film"></i>
                <p>Videos</p>
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/images">
              <li>
                <i className="fas fa-images"></i>
                <p>Images</p>
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/about">
              <li>
                <i class="far fa-envelope"></i>
                <p>Contact</p>
              </li>
            </Link>

            <a href="tel:877-401-0730" style={{ textDecoration: "none" }}>
              <li>
                <i class="fas fa-phone"></i>
                <p>Call Us</p>
              </li>
            </a>
            <a
              href="http://portal.rpscorporation.com"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <li>
                <i className="fas fa-user"></i>
                <p>Login</p>
              </li>
            </a>
          </ul>
        </div>
      </MobileMainLinkStyle>
    </div>
  )
}

export default MobileNavMainLinks
