import React from "react"
import { MobileSubNavStyle } from "./styles/MobileSubNavStyle"
import { If } from "rc-if-else"
// import Image1 from "../../images/rubicon1.png"
const MobileAbout = props => {
  return (
    <MobileSubNavStyle>
      <div className="sub-menu-container w3-animate-left">
        <div id="top-heading-back">
          <i onClick={props.toggleMainMenu} className="fas fa-chevron-left"></i>
          <h3>About</h3>
        </div>
        <div className="sub-menu-links">
          <ul>
            <li>
              <p onClick={props.toggleContactMenu}>Contact</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.contactMenu}>Contact info</If>
            </li>

            <li>
              <p onClick={props.toggleCompanyMenu}>Company</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.companyMenu}>Company Info Stuff</If>
            </li>
            <li>
              <p onClick={props.toggleHistoryMenu}>History</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.historyMenu}>History Stuff</If>
            </li>
          </ul>
        </div>
      </div>
    </MobileSubNavStyle>
  )
}

export default MobileAbout
