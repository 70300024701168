import React from 'react'
import'./mainnav.scss'
import Logo from "../../images/timberline-white-logo-solo.png"
import { Link } from 'gatsby'

export default function DesktopNav(props) {
  let newProps = props.props
  console.log(props)
  return (
    <div className="desktop-nav">
        <ul className="logo">
            <li> 
                <img src={Logo} alt="logo" />
            </li>
        </ul>
        <ul className="links">
            <li><Link to="/">Home</Link></li>
            <li onClick={newProps.toggleDropDown} >Floor Scrubbers</li>
            <li><Link to="/videos">Videos</Link></li>
            <li><Link to="/images">Images</Link></li>
            <li><Link to="/support">Support</Link></li>
            <li><Link to="/about">About</Link></li>
            <li className="login"> <a href='https://portal.rpscorporation.com' target="_blank">Login</a></li>
            <li className="phone"><a href="tel:1-877-401-0730" >1-877-401-0730</a></li>
        </ul>
    </div>
  )
}
