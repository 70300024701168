import React from "react"
import { MobileSubNavStyle } from "./styles/MobileSubNavStyle"
import { If } from "rc-if-else"
import Image1 from "../../images/rubicon1.png"

const MobileSupport = props => {
  return (
    <MobileSubNavStyle>
      <div className="sub-menu-container w3-animate-left">
        <div id="top-heading-back">
          <i onClick={props.toggleMainMenu} className="fas fa-chevron-left"></i>
          <h3>Support</h3>
        </div>
        <div className="sub-menu-links">
          <ul>
            <li>
              <p onClick={props.toggleOpMenu}>Operator Manuals</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.opMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <div className="sub-menu-section">
                    <p>Small</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Medium</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Large</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Xtra-Large</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.togglePartsMenu}>Parts Manuals</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.partsMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <div className="sub-menu-section">
                    <p>Small</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Medium</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Large</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Xtra-Large</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleServiceMenu}>Service</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.serviceMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <div className="sub-menu-section">
                    <p>Technical Support</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Warranty</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleSerialMenu}>Serial Number Lookup</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.serialMenu}>
                <div className="serial-number-lookup">
                  This is where we will look up serial numbers.
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleLitMenu}>Literature</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.litMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <div className="sub-menu-section">
                    <p>Brochures</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Spec Sheets</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                  <div className="sub-menu-section">
                    <p>Bid Specs</p>
                    <img src={Image1} alt="" />
                    <p style={{ fontSize: "x-small" }}>
                      Here is some info about the product
                    </p>
                  </div>
                </div>
              </If>
            </li>
          </ul>
        </div>
      </div>
    </MobileSubNavStyle>
  )
}
export default MobileSupport
