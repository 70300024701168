import React, { Component } from "react"
import { MobileNavStyles } from "./styles/MobileNavStyles"
import MobileNavMainLinks from "./MobileNavMainLinks"
import MobileProducts from "./MobileProducts"

import { If } from "rc-if-else"
import MobileSupport from "./MobileSupport"
import MobileAbout from "./MobileAbout"

class MobileNavigation extends Component {
  state = {
    productMenu: false,
    supportMenu: false,
    aboutMenu: false,
    mainMenu: true,

    subMenu: false,

    floorMenu: false,
    xsCompactMenu: false,
    xsStandardMenu: false,
    smallMenu: false,
    medMenu: false,
    largeMenu: false,
    xlMenu: false,

    opMenu: false,
    partsMenu: false,
    serviceMenu: false,
    serialMenu: false,
    litMenu: false,

    contactMenu: false,
    companyMenu: false,
    historyMenu: false,

    toggleChevronUp: true,
    toggleChevronDown: false,
  }

  toggleContactMenu = () => {
    if (!this.state.contactMenu) {
      this.setState({
        contactMenu: true,
        companyMenu: false,
        historyMenu: false,
      })
    } else if (this.state.contactMenu) {
      this.setState({ contactMenu: false })
    }
  }

  toggleCompanyMenu = () => {
    if (!this.state.companyMenu) {
      this.setState({
        contactMenu: false,
        companyMenu: true,
        historyMenu: false,
      })
    } else if (this.state.companyMenu) {
      this.setState({ companyMenu: false })
    }
  }

  toggleHistoryMenu = () => {
    if (!this.state.historyMenu) {
      this.setState({
        contactMenu: false,
        companyMenu: false,
        historyMenu: true,
      })
    } else if (this.state.historyMenu) {
      this.setState({ historyMenu: false })
    }
  }

  toggleOpMenu = () => {
    if (!this.state.opMenu) {
      this.setState({
        opMenu: true,
        partsMenu: false,
        serviceMenu: false,
        serialMenu: false,
        litMenu: false,
      })
    } else if (this.state.opMenu) {
      this.setState({ opMenu: false })
    }
  }

  togglePartsMenu = () => {
    if (!this.state.partsMenu) {
      this.setState({
        opMenu: false,
        partsMenu: true,
        serviceMenu: false,
        serialMenu: false,
        litMenu: false,
      })
    } else if (this.state.partsMenu) {
      this.setState({ partsMenu: false })
    }
  }

  toggleServiceMenu = () => {
    if (!this.state.serviceMenu) {
      this.setState({
        opMenu: false,
        partsMenu: false,
        serviceMenu: true,
        serialMenu: false,
        litMenu: false,
      })
    } else if (this.state.serviceMenu) {
      this.setState({ serviceMenu: false })
    }
  }

  toggleSerialMenu = () => {
    if (!this.state.serialMenu) {
      this.setState({
        opMenu: false,
        partsMenu: false,
        serviceMenu: false,
        serialMenu: true,
        litMenu: false,
      })
    } else if (this.state.serialMenu) {
      this.setState({ serialMenu: false })
    }
  }

  toggleLitMenu = () => {
    if (!this.state.litMenu) {
      this.setState({
        opMenu: false,
        partsMenu: false,
        serviceMenu: false,
        serialMenu: false,
        litMenu: true,
      })
    } else if (this.state.litMenu) {
      this.setState({ litMenu: false })
    }
  }

  toggleXsCompactMenu = () => {
    if (!this.state.xsCompactMenu) {
      this.setState({
        xsCompactMenu: true,
        xsStandardMenu: false,
        floorMenu: false,
        smallMenu: false,
        medMenu: false,
        largeMenu: false,
        xlMenu: false,
        toggleChevronUp: true,
        toggleChevronDown: false,
      })
    } else if (this.state.xsCompactMenu) {
      this.setState({
        xsCompactMenu: false,
        toggleChevronUp: false,
        toggleChevronDown: true,
      })
    }
  }

  toggleXsStandardMenu = () => {
    if (!this.state.xsStandardMenu) {
      this.setState({
        xsCompactMenu: false,
        xsStandardMenu: true,
        floorMenu: false,
        smallMenu: false,
        medMenu: false,
        largeMenu: false,
        xlMenu: false,
        toggleChevronUp: true,
        toggleChevronDown: false,
      })
    } else if (this.state.xsStandardMenu) {
      this.setState({
        xsStandardMenu: false,
        toggleChevronUp: false,
        toggleChevronDown: true,
      })
    }
  }

  toggleFloorMenu = () => {
    if (!this.state.floorMenu) {
      this.setState({
        xsCompactMenu: false,
        xsStandardMenu: false,
        floorMenu: true,
        smallMenu: false,
        medMenu: false,
        largeMenu: false,
        xlMenu: false,
        toggleChevronUp: true,
        toggleChevronDown: false,
      })
    } else if (this.state.floorMenu) {
      this.setState({
        floorMenu: false,
        toggleChevronUp: false,
        toggleChevronDown: true,
      })
    }
  }

  toggleSmallMenu = () => {
    if (!this.state.smallMenu) {
      this.setState({
        xsCompactMenu: false,
        xsStandardMenu: false,
        floorMenu: false,
        smallMenu: true,
        medMenu: false,
        largeMenu: false,
        xlMenu: false,
        toggleChevronUp: true,
        toggleChevronDown: false,
      })
    } else if (this.state.smallMenu) {
      this.setState({
        smallMenu: false,
        toggleChevronUp: false,
        toggleChevronDown: true,
      })
    }
  }

  toggleMedMenu = () => {
    if (!this.state.medMenu) {
      this.setState({
        xsCompactMenu: false,
        xsStandardMenu: false,
        floorMenu: false,
        smallMenu: false,
        medMenu: true,
        largeMenu: false,
        xlMenu: false,
      })
    } else if (this.state.medMenu) {
      this.setState({ medMenu: false })
    }
  }

  toggleLargeMenu = () => {
    if (!this.state.largeMenu) {
      this.setState({
        xsCompactMenu: false,
        xsStandardMenu: false,
        floorMenu: false,
        smallMenu: false,
        medMenu: false,
        largeMenu: true,
        xlMenu: false,
      })
    } else if (this.state.largeMenu) {
      this.setState({ largeMenu: false })
    }
  }

  toggleXlMenu = () => {
    if (!this.state.xlMenu) {
      this.setState({
        smallMenu: false,
        medMenu: false,
        largeMenu: false,
        xlMenu: true,
      })
    } else if (this.state.xlMenu) {
      this.setState({ xlMenu: false })
    }
  }

  toggleMainMenu = () => {
    this.setState({
      mainMenu: true,
      productMenu: false,
      supportMenu: false,
      aboutMenu: false,
      smallMenu: false,
      medMenu: false,
      largeMenu: false,
      xlMenu: false,
    })
  }

  toggleProductMenu = () => {
    if (!this.state.productMenu) {
      this.setState({
        productMenu: true,
        mainMenu: false,
        supportMenu: false,
        aboutMenu: false,
      })
    } else if (this.state.productMenu) {
      this.setState({ productMenu: false })
    }
  }

  toggleSupportMenu = () => {
    if (!this.state.supportMenu) {
      this.setState({
        productMenu: false,
        mainMenu: false,
        supportMenu: true,
        aboutMenu: false,
      })
    } else if (this.state.supportMenu) {
      this.setState({ supportMenu: false })
    }
  }

  toggleAboutMenu = () => {
    if (!this.state.aboutMenu) {
      this.setState({
        productMenu: false,
        mainMenu: false,
        supportMenu: false,
        aboutMenu: true,
      })
    } else if (this.state.aboutMenu) {
      this.setState({ aboutMenu: false })
    }
  }
  render() {
    return (
      <div>
        <MobileNavStyles>
          <div className="w3-animate-left" id="mobile-nav-container">
            {/* <div className="mobile-search">
              <input type="text" placeholder="Search" />
              <i className="fas fa-search"></i>
            </div> */}
            <If condition={this.state.mainMenu}>
              <MobileNavMainLinks
                toggleSupportMenu={this.toggleSupportMenu}
                toggleProductMenu={this.toggleProductMenu}
                toggleAboutMenu={this.toggleAboutMenu}
              />
            </If>
            <If condition={this.state.productMenu}>
              <MobileProducts
                toggleXsCompactMenu={this.toggleXsCompactMenu}
                toggleXsStandardMenu={this.toggleXsStandardMenu}
                toggleFloorMenu={this.toggleFloorMenu}
                toggleSmallMenu={this.toggleSmallMenu}
                toggleMedMenu={this.toggleMedMenu}
                toggleLargeMenu={this.toggleLargeMenu}
                toggleXlMenu={this.toggleXlMenu}
                toggleMainMenu={this.toggleMainMenu}
                floorMenu={this.state.floorMenu}
                xsCompactMenu={this.state.xsCompactMenu}
                xsStandardMenu={this.state.xsStandardMenu}
                smallMenu={this.state.smallMenu}
                medMenu={this.state.medMenu}
                largeMenu={this.state.largeMenu}
                xlMenu={this.state.xlMenu}
                toggleChevronUp={this.state.toggleChevronUp}
              />
            </If>
            <If condition={this.state.supportMenu}>
              <MobileSupport
                toggleOpMenu={this.toggleOpMenu}
                togglePartsMenu={this.togglePartsMenu}
                toggleServiceMenu={this.toggleServiceMenu}
                toggleSerialMenu={this.toggleSerialMenu}
                toggleLitMenu={this.toggleLitMenu}
                toggleMainMenu={this.toggleMainMenu}
                opMenu={this.state.opMenu}
                partsMenu={this.state.partsMenu}
                serviceMenu={this.state.serviceMenu}
                serialMenu={this.state.serialMenu}
                litMenu={this.state.litMenu}
                toggleChevronUp={this.state.toggleChevronUp}
              />
            </If>
            <If condition={this.state.aboutMenu}>
              <MobileAbout
                toggleMainMenu={this.toggleMainMenu}
                toggleContactMenu={this.toggleContactMenu}
                toggleCompanyMenu={this.toggleCompanyMenu}
                toggleHistoryMenu={this.toggleHistoryMenu}
                contactMenu={this.state.contactMenu}
                companyMenu={this.state.companyMenu}
                historyMenu={this.state.historyMenu}
                toggleChevronUp={this.state.toggleChevronUp}
              />
            </If>
          </div>
        </MobileNavStyles>
      </div>
    )
  }
}

export default MobileNavigation
