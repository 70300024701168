import styled from "styled-components"

export const MobileNavStyles = styled.div`
  #mobile-nav-container {
    position: fixed;
    overflow: scroll;
    top: 60px;
    width: 100vw;
    height: 100%;
    background-color: white;
    z-index: 4;

    .mobile-search {
      input {
        width: 100%;
        height: 65px;
        padding: 1rem;
        z-index: 1;
        font-size: x-large;
        background-color: lightgray;
        border: none;
      }

      i {
        font-size: 30px;
        position: absolute;
        top: 2.5%;
        right: 7%;
      }
    }
  }
  @media (min-width: 1140px) {
    display: none;
  }
`
