import React from 'react'
import './mainnav.scss'
import Logo from "../../images/timberline-white-logo-solo.png"

export default function MobileNav(props) {
  let newProps = props.props
  return (
    <div className="mobile-nav">
        <ul>
          <li><i onClick={newProps.toggleMobileNav} className="fas fa-bars"></i></li>
          <li className="logo"><img src={Logo} alt="logo" /></li>
        </ul>
    </div>
  )
}