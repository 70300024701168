import React from "react"
import MainNavBar from "./MainNavBar"
import MainNavDropDown from "./MainNavDropDown"

const Header = props => {
  return (
    <div>
      <MainNavBar
        toggleDropDown={props.toggleDropDown}
        toggleMobileNav={props.toggleMobileNav}
      />
      <MainNavDropDown dropDownMargin={props.dropDownMargin} />
    </div>
  )
}

export default Header
