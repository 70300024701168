import styled from "styled-components"

export const MobileSubNavStyle = styled.div`
  #top-heading-back {
    display: grid;
    grid-template-columns: 15% 85%;
    grid-column-gap: 15px;
    align-items: center;
    font-size: x-large;

    .fa-chevron-left {
      &:hover {
        cursor: pointer;
      }
    }

    i {
      color: #5595f7;
      justify-self: center;
    }

    h3 {
      color: #55555e;
    }
  }

  .sub-menu-links {
    ul {
      list-style-type: none;
      height: 1000px;
      /* padding-left: 80px; */

      li {
        width: 100%;
        display: grid;
        grid-template-columns: 90% 10%;
        align-items: center;
        border-bottom: 1px solid rgba(85, 149, 247, 0.1);
        color: #5595f7;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .sub-menu-list {
    /* margin-left: 10%; */

    .sub-menu-section {
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;

      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      padding: 10px;
      border-bottom: 1px solid rgba(85, 149, 247, 0.1);
    }
    img {
      width: 80px;
      justify-self: right;
    }
  }
`
