import React, { Component } from "react"
import Header from "./Header"
import MobileNavigation from "../navigation/MobileNavigation"
import { If } from "rc-if-else"

class Navigation extends Component {
  state = {
    dropDownMargin: "-900px",

    mobileNav: false,
  }

  toggleDropDown = () => {
    if (this.state.dropDownMargin === "-900px") {
      this.setState({ dropDownMargin: "0" })
    } else {
      this.setState({ dropDownMargin: "-900px" })
    }
  }

  toggleMobileNav = () => {
    if (!this.state.mobileNav) {
      this.setState({ mobileNav: true })
    } else if (this.state.mobileNav) {
      this.setState({ mobileNav: false })
    }
  }

  render() {
    return (
      <div>
        <Header
          dropDownMargin={this.state.dropDownMargin}
          toggleDropDown={this.toggleDropDown}
          toggleMobileNav={this.toggleMobileNav}
        />

        <If condition={this.state.mobileNav}>
          <MobileNavigation />
        </If>
      </div>
    )
  }
}

export default Navigation
