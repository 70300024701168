import React, { Component } from "react"
import Navigation from "../components/navigationV2/Navigation"
// import Header from "../components/navigationV2/Header"
import Footer from "../components/footer/Footer"
import { ParallaxProvider } from "react-scroll-parallax"
import { Helmet } from "react-helmet"
import { GlobalStyles } from "./styles/GlobalStyles"


// ReactGA.initialize("UA-157521814-1")
// ReactGA.pageview(window.location.pathname + window.location.search)

class MainLayout extends Component {
  state = {
    chatModal: false,
  }

  toggleChatModal = () => {
    if (!this.state.chatModal) {
      this.setState({ chatModal: true })
    } else if (this.state.chatModal) {
      this.setState({ chatModal: false })
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
            integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
            crossOrigin="anonymous"
          />

          <link
            rel="stylesheet"
            href="https://www.w3schools.com/w3css/4/w3.css"
          />

          <link
            href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <GlobalStyles>
          <ParallaxProvider>
            <Navigation />

            {this.props.children}

            <Footer />
          </ParallaxProvider>
        </GlobalStyles>
      </div>
    )
  }
}

export default MainLayout
