import React from "react"
import { MobileSubNavStyle } from "./styles/MobileSubNavStyle"
import { If } from "rc-if-else"
import { Link } from "gatsby"

import S17 from "../../images/TIMBERLINE-S-SERIES-17-D-front-WEB.png"
import S24 from "../../images/TIMBERLINE-S-SERIES-24-C-front-WEB.png"
import S20 from "../../images/TIMBERLINE-S-SERIES-17-D-front-WEB.png"
import S20O from "../../images/TIMBERLINE-S-SERIES-20-O-front-WEB.png"
import M28O from "../../images/RUBICON-M-SERIES-28-ORBITAL-SCRUBBER.png"
import M28D from "../../images/TIMBERLINE-M-SERIES-28-D-front-WEB.png"
import M26D from "../../images/TIMBERLINE-M-SERIES-26-D-front-WEB.png"
import M26C from "../../images/TIMBERLINE-M-SERIES-26-C-front-WEB.png"
import M24O from "../../images/TIMBERLINE-M-SERIES-24-O-front-WEB.png"
import L32D from "../../images/TIMBERLINE-L-SERIES-32-D-front-WEB.png"
import L30D from "../../images/RUBICON-L-SERIES-30-DISK-SCRUBBER.png"
import L30C from "../../images/TIMBERLINE-L-SERIES-30-C-front-WEB.png"
import L28O from "../../images/TIMBERLINE-L-SERIES-28-O-front-WEB.png"
import L28D from "../../images/TIMBERLINE-L-SERIES-28-D-front-WEB.png"
import L26D from "../../images/RUBICON-L-SERIES-26-DISK-SCRUBBER.png"
import L26C from "../../images/TIMBERLINE-L-SERIES-26-C-front-WEB.png"
import L24O from "../../images/TIMBERLINE-L-SERIES-24-O-front-WEB.png"
import M20D from "../../images/timberline-m-20-D-FRONT-THUMB.jpg"
import Orb20 from "../../images/orbitz-20-full-view-w80.jpg"
import Orb10 from "../../images/orbitz10-w80.jpg"

import Xs17PA from "../../images/xs-compact-17d-pa-1-x80.jpg"
import Xs17Tr from "../../images/xs-compact-17d-traction-w80.jpg"
import Xs20PA from "../../images/xs-compact-20d-pa-1-w80.jpg"
import Xs20Tr from "../../images/xs-compact-20D-TRACTION-w80.jpg"

import XsS17PA from "../../images/xs-standard-17d-pa-w80.jpg"
import XsS17Tr from "../../images/xs-standard-17d-traction-w80.jpg"
import XsS20PA from "../../images/xs-standard-20d-padassist-w80.jpg"
import XsS20Tr from "../../images/xs-standard-20D-TRACTION-w80.jpg"
import XsS20Orb from "../../images/xs-standard-20o-w80.jpg"

const MobileProducts = props => {
  return (
    <MobileSubNavStyle>
      <div className="sub-menu-container w3-animate-left">
        <div id="top-heading-back">
          <i onClick={props.toggleMainMenu} className="fas fa-chevron-left"></i>
          <h3>Floor Scrubbers</h3>
        </div>
        <div className="sub-menu-links">
          <ul>
            <li>
              <p onClick={props.toggleFloorMenu}>Floor machines</p>
              <If condition={true}>
                <i
                  onClick={props.toggleFloorMenu}
                  className="fas fa-chevron-down"
                ></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.floorMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/orbitz10-floor-machine"
                  >
                    <div className="sub-menu-section">
                      <p>10" Orbitz Floor Machine</p>

                      <img src={Orb10} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/orbitz20-floor-machine"
                  >
                    <div className="sub-menu-section">
                      <p>20" Orbitz Floor Machine</p>
                      <img src={Orb20} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li>

            {/* <li>
              <p onClick={props.toggleXsCompactMenu}>XS-Compact</p>
              <If condition={true}>
                <i
                  onClick={props.toggleXsCompactMenu}
                  className="fas fa-chevron-down"
                ></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.xsCompactMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs17-disk-pad-assist-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS17 Compact - Pad Assist</p>

                      <img src={Xs17PA} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs17-disk-traction-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS17 Compact - Traction</p>
                      <img src={Xs17Tr} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs20-disk-pad-assist-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS20 Compact - Pad Assist</p>
                      <img src={Xs20PA} alt="Timberline XS Traction" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs20-disk-traction-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS20 Compact - Traction</p>
                      <img src={Xs20Tr} alt="Timberline XS20 Traction" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li> */}

            {/* <li>
              <p onClick={props.toggleXsStandardMenu}>XS-Standard</p>
              <If condition={true}>
                <i
                  onClick={props.toggleXsStandardMenu}
                  className="fas fa-chevron-down"
                ></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.xsStandardMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs17-standard-disk-pad-assist-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS17 Standard - Pad Assist</p>

                      <img src={XsS17PA} alt="XS17 Standard - Pad Assist" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs17-standard-disk-traction-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS17 Standard - Traction</p>
                      <img src={XsS17Tr} alt="XS17 Standard - Traction" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs20-standard-disk-pad-assist-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS20 Standard - Pad Assist</p>
                      <img src={XsS20PA} alt="XS20 Standard - Pad Assist" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs20-standard-disk-traction-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS20 Standard - Traction</p>
                      <img src={XsS20Tr} alt="XS20 Standard - Traction" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xs20-standard-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XS20 Standard - Orbital</p>
                      <img src={XsS20Orb} alt="XS20 Standard - Orbital" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li> */}

            <li>
              <p onClick={props.toggleSmallMenu}>S-Series</p>
              <If condition={true}>
                <i
                  onClick={props.toggleSmallMenu}
                  className="fas fa-chevron-down"
                ></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.smallMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s17-disk-scrubber-pad-assist"
                  >
                    <div className="sub-menu-section">
                      <p>S17 Disk Scrubber - Pad Assist</p>

                      <img src={S17} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s17-disk-scrubber-pad-assist"
                  >
                    <div className="sub-menu-section">
                      <p>S20 Disk Scrubber - Pad Assist</p>
                      <img src={S17} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s17-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>S17 Disk Scrubber</p>
                      <img src={S17} alt="Timberline S17 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s20-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>S20 Disk Scrubber</p>
                      <img src={S20} alt="Timberline S20 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s24-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>S24 Cylindrical Scrubber</p>
                      <img
                        src={S24}
                        alt="Timberline S24 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/s20-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>S20 Orbital Scrubber</p>
                      <img src={S20O} alt="Timberline S20 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleMedMenu}>M-Series</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.medMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m20-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M20 Disk Scrubber</p>
                      <img src={M20D} alt="Timberline M20 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m26-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M26 Disk Scrubber</p>
                      <img src={M26D} alt="Timberline M26 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m28-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M28 Disk Scrubber</p>
                      <img src={M28D} alt="Timberline M28 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m26-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M26 Cylindrical Scrubber</p>
                      <img
                        src={M26C}
                        alt="Timberline M26 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m20-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M20 Orbital Scrubber</p>
                      <img src={M24O} alt="Timberline M20 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link> */}
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m24-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M24 Orbital Scrubber</p>
                      <img src={M24O} alt="Timberline M24 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/m28-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>M28 Orbital Scrubber</p>
                      <img src={M28O} alt="Timberline M28 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleLargeMenu}>L-Series</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.largeMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l26-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L26 Disk Scrubber</p>
                      <img src={L28D} alt="Timberline L28 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l28-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L28 Disk Scrubber</p>
                      <img src={L32D} alt="Timberline L28 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l26-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L26 Cylindrical Scrubber</p>
                      <img
                        src={L26C}
                        alt="Timberline L26 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  {/* <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l30-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L30 Cylindrical Scrubber</p>
                      <img
                        src={L30C}
                        alt="Timberline L30 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link> */}
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l24-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L24 Orbital Scrubber</p>
                      <img
                        src={L24O}
                        alt="Timberline L24 Orbital ScrubberL24 Orbital Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l28-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L28 Orbital Scrubber</p>
                      <img src={L28O} alt="Timberline L28 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li>
            <li>
              <p onClick={props.toggleXlMenu}>XL-Series</p>
              <If condition={true}>
                <i className="fas fa-chevron-down"></i>
              </If>
              <If condition={false}>
                <i className="fas fa-chevron-up"></i>
              </If>
              <If condition={props.xlMenu}>
                <div className="sub-menu-list w3-animate-bottom">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl28-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XL28 Disk Scrubber</p>
                      <img src={L28D} alt="Timberline XL28 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/l32-disk-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>L32 Disk Scrubber</p>
                      <img src={L32D} alt="Timberline L32 Disk Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl26-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XL26 Cylindrical Scrubber</p>
                      <img
                        src={L26C}
                        alt="Timberline XL26 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl30-cylindrical-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XL30 Cylindrical Scrubber</p>
                      <img
                        src={L30C}
                        alt="Timberline XL30 Cylindrical Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl24-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XL24 Orbital Scrubber</p>
                      <img
                        src={L24O}
                        alt="Timberline XL24 Orbital ScrubberL24 Orbital Scrubber"
                      />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/products/xl28-orbital-scrubber"
                  >
                    <div className="sub-menu-section">
                      <p>XL28 Orbital Scrubber</p>
                      <img src={L28O} alt="Timberline XL28 Orbital Scrubber" />
                      <p style={{ fontSize: "x-small" }}></p>
                    </div>
                  </Link>
                </div>
              </If>
            </li>
          </ul>
        </div>
      </div>
    </MobileSubNavStyle>
  )
}
export default MobileProducts
