import React from "react"
import'./mainnav.scss'
import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"

const MainNavBar = props => {
  console.log(props)
  return (
    <div className="main-nav">
      <DesktopNav props={props}/>
      <MobileNav props={props}/>
    </div>
  )
}

export default MainNavBar
