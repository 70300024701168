import { Link } from "gatsby";
import React from "react"
import './footer.scss';

const Footer = props => {
  return (
    <div>
      <div className="desktop-main-footer-container">
          <ul>
            <li className="section-title"><Link to="/floor-scrubbers"> Floor Scrubbers</Link></li>
            <li><Link to="/floor-machine-series">Floor Machines</Link></li>
            <li><Link to="/s-series">S-Series</Link></li>
            <li><Link  to="/m-series"> M-Series</Link></li>
            <li><Link  to="/l-series">L-Series</Link></li>
            <li><Link  to="/xl-series">XL-Series</Link></li>
            <li><Link to="/products/r-series-scrubber">Rider-Series</Link></li>
          </ul>
          <ul>
            <li className="section-title"><Link to="/support">Support</Link></li>
            <li><Link  to="/support">Brochures</Link></li>
            <li><Link  to="/support">Tech-Specs</Link></li>
            <li><Link  to="/support">Operator Manual</Link></li>
            <li><Link  to="/support">Warranty</Link></li>
          </ul>
          <ul>
            <li className="section-title"><Link to="/about">About</Link></li>
            <li><Link  to="/about">Contact</Link></li>
          </ul>
          <ul>
            <li className="section-title"><Link to="/about">Social</Link></li>
            <li>
              <a href="https://www.facebook.com/TimberlineCleaning" target="_blank">
              <i  className="fab fa-facebook-f"></i>Facebook</a></li>
            <li>
              <a href="https://www.instagram.com/timberlinecleaning/" target="_blank">
              <i  className="fab fa-instagram" ></i>Instagram </a> </li>
            <li>
              <a href="https://www.linkedin.com/company/timberline-cleaning-equipment/" target="_blank" >
              <i  className="fab fa-linkedin-in" ></i>LinkedIn </a>
            </li>
            <li>
              <a href="https://twitter.com/TimberlineEquip" target="_blank" >
              <i  className="fab fa-twitter"></i>Twitter </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCJgT7enTEieMPJFIDxWXz9A" target="_blank" >
              <i  className="fab fa-youtube"></i>You Tube </a>
            </li>
          </ul>
            <ul className="footer-note">
              <li> 1(877) 401-0730 &copy; 2019 R.P.S. Corporation{" "}</li>
            </ul>

      </div>
      <div className="mobile-main-footer-container">
        <ul>
          <li><Link to="/floor-scrubbers"> Floor Scrubbers</Link></li>
          <li><Link to="/support">Support</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>
        <div className="footer-social-icons">
          <a href="https://www.facebook.com/TimberlineCleaning" target="_blank">
              <i  className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/timberlinecleaning/" target="_blank">
              <i  className="fab fa-instagram" ></i></a> 
          <a href="https://www.linkedin.com/company/timberline-cleaning-equipment/" target="_blank" >
              <i  className="fab fa-linkedin-in" ></i></a>
          <a href="https://twitter.com/TimberlineEquip" target="_blank" >
              <i  className="fab fa-twitter"></i></a>
          <a href="https://www.youtube.com/channel/UCJgT7enTEieMPJFIDxWXz9A" target="_blank" >
              <i className="fab fa-youtube"></i></a>
        </div>
        <ul>
          <li className="footer-note"> 1(877) 401-0730 &copy; 2022 R.P.S. Corporation{" "}</li>
        </ul>
      </div>
    </div>


  )
}

export default Footer
